import styled from "styled-components";
import React, { FunctionComponent } from "react";

const CenterFillPageContainer = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  flex-flow: column nowrap;
  justify-content: center;
  align-items: center;
  background: #1a1f35;
  color: #ebeff0;
`;

const PageCard = styled.div`
  padding: 1.5em;
  background: #262c42;
  box-shadow: 0 2px 6px 2px #111e22;
  display: flex;
  flex-flow: column nowrap;
  justify-content: center;
  align-items: center;
`;

const FullPageCard: FunctionComponent = (props) => {
  return (
    <CenterFillPageContainer>
      <PageCard>{props.children}</PageCard>
    </CenterFillPageContainer>
  );
};

export default FullPageCard;
