// FIXME ***** STATIC BUNDLING FOR NOW BECAUSE DYNAMIC LOADING MAKES THINGS MORE COMPLICATED *****
import ChatClient from "@reality/subsystem-chat/lib/client";
import ChatManifest from "@reality/subsystem-chat/lib/manifest";
import SignsClient from "@reality/subsystem-signs/lib/client";
import SignsManifest from "@reality/subsystem-signs/lib/manifest";
import VoiceClient from "@reality/subsystem-voice/lib/client";
import VoiceManifest from "@reality/subsystem-voice/lib/manifest";
import LanternPuzzleClient from "@reality/subsystem-lantern-puzzle/lib/client";
import LanternPuzzleManifest from "@reality/subsystem-lantern-puzzle/lib/manifest";
import RiddleClient from "@reality/subsystem-riddle/lib/client";
import RiddleManifest from "@reality/subsystem-riddle/lib/manifest";
import NamedZoneClient from "@reality/subsystem-named-zones/lib/client";
import NamedZoneManifest from "@reality/subsystem-named-zones/lib/manifest";
import SubsystemManager from "../SubsystemManager";
import { namedZoneConfig } from "./namedZoneConfig";
import { signConfig } from "./signConfig";
import { voiceConfig } from "./voiceConfig";

export const registerSubsystems = (manager: SubsystemManager) => {
  // TODO - Remember to add the dependency in package.json when you add a new subsystem
  manager.fakeDynamicLoadSubsystem(ChatManifest, ChatClient, {});
  manager.fakeDynamicLoadSubsystem(SignsManifest, SignsClient, signConfig);
  manager.fakeDynamicLoadSubsystem(VoiceManifest, VoiceClient, voiceConfig);
  manager.fakeDynamicLoadSubsystem(LanternPuzzleManifest, LanternPuzzleClient, {});
  manager.fakeDynamicLoadSubsystem(RiddleManifest, RiddleClient, {});
  manager.fakeDynamicLoadSubsystem(NamedZoneManifest, NamedZoneClient, namedZoneConfig);
};
