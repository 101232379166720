import { ZoneConfig } from "@reality/subsystem-named-zones/lib/common";

export const namedZoneConfig: ZoneConfig = [
  {
    zoneName: "The Ave.",
    viewLocation: { x: 26, y: 13 },
  },
  {
    zoneName: "Status 301",
    viewLocation: { x: 29, y: 20 },
  },
  {
    zoneName: "Status 200",
    viewLocation: { x: 29, y: 15 },
  },
  {
    zoneName: "Suzz.",
    viewLocation: { x: 24, y: 21 },
  },
  {
    zoneName: "Snack Overflow",
    viewLocation: { x: 40, y: 13 },
  },
];
