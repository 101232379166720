import { ArtemisAPI } from "@reality/artemis-api/lib/api/artemis";
import { createClient } from "@reality/artemis-api/lib/client/client";
import { ClientAPI } from "@reality/artemis-api/lib/client/ClientAPI";
import { ClientResourceManager } from "@reality/client-utils";
import ClientAnimationManager from "@reality/entity/lib/client-utils/ClientAnimationManager";
import { MessengerClient } from "@reality/messenger/lib/client";
import { Region } from "@reality/region-utils";
import Resource from "@reality/resource";
import { pull } from "lodash";
import React, { useEffect, useState } from "react";
import { FriendsList } from "../components/FriendsList";
import EntityManager from "../entities/EntityManager";
import { registerEntities } from "../entities/hardcode/registration";
import Login from "../pages/Login";
import Reality from "../reality/Reality";
import { registerSubsystems } from "../subsystems/hardcode/registration";
import SubsystemManager from "../subsystems/SubsystemManager";

// TODO - This code sucks. We're probably going to want a somewhat more heavy-duty version of
//        this, though.

enum State {
  UNAUTHENTICATED,
  CONNECTED_ARTEMIS,
  CONNECTED_GAIA,
}

class SystemStateManager {
  private _artemisAPI: ClientAPI<typeof ArtemisAPI>;
  private _state: State = State.UNAUTHENTICATED;
  private _handlers: (() => void)[] = [];
  private _errorText?: string;
  private _isLoading: boolean = false;
  //
  private _artemisToken?: string;
  private _client?: MessengerClient;
  private _username?: string;
  private _userEntityId?: string;
  //
  private _animationManager?: ClientAnimationManager;
  private _resourceManager?: ClientResourceManager;
  private _entityManager?: EntityManager;
  private _subsystemManager?: SubsystemManager;
  private _resource?: Resource;
  //
  private _region?: Region;

  constructor() {
    this._artemisAPI = createClient(ArtemisAPI, {
      host: process.env.NODE_ENV === "development" ? "http://localhost" : "https://artemis.reality.dubhacks.co",
      port: 4000,
    });
  }

  artemisAPI = () => {
    return this._artemisAPI;
  };

  initializeUserEntityId = (id: string) => {
    this._userEntityId = id;
  };

  authenticate = async (username: string, password: string) => {
    this._isLoading = true;
    this._errorText = undefined;
    const res = await this._artemisAPI.account.login.send({
      username,
      password,
    });
    if (!res.success) {
      this._isLoading = false;
      if (res.messageForUser) {
        this._errorText = res.message;
      } else {
        console.error("Login Error:", res.message);
        this._errorText = "An unknown error prevented login. Please try again.";
      }
      this.emitChange();
      return;
    }
    //
    await this.completeArtemisConnection(res.body.artemisToken, username);
  };

  completeArtemisConnection = async (artemisToken: string, username: string) => {
    this._isLoading = true;
    this._artemisToken = artemisToken;
    this._username = username;
    this._state = State.CONNECTED_ARTEMIS;
    this.emitChange();
  };

  joinRegion = async () => {
    await this.connectToRegion("region1");
  };

  private connectToRegion = async (regionId: string) => {
    while (true) {
      const res = await this._artemisAPI.cluster.dispatch.send({ regionId }, this.artemisToken());
      if (!res.success) {
        this._isLoading = false;
        if (res.messageForUser) {
          this._errorText = res.message;
        } else {
          console.error("Login Error:", res.message);
          this._errorText = "An unknown error prevented login. Please try again.";
        }
        this.emitChange();
        return;
      }
      const body = res.body;
      if (body.success) {
        // Got assignment - connect to gaia
        this._resource = new Resource(body.assetServer);
        this._region = await this._resource.region(regionId);
        await this.connectToGaia(body.gaiaHost, body.gaiaPort, body.gaiaToken);
        console.log("connect returned");
        return;
      }
      // Didn't get assignment - wait and try again
      console.log("Didn't get region assignment - trying again in 1 second...");
      await new Promise((resolve) => setTimeout(resolve, 1000));
    }
  };

  private connectToGaia = async (gaiaHost: string, gaiaPort: number, gaiaToken: string) => {
    console.log("configuring client");
    const client = new MessengerClient({
      secure: process.env.NODE_ENV !== "development",
      host: gaiaHost,
      port: gaiaPort,
      authenticate: () => ({ gaiaToken }),
    });
    client.on("disconnect", () => {
      this._state = State.UNAUTHENTICATED;
      this._isLoading = false;
      this._errorText = undefined;
      this._client = undefined;
      this._subsystemManager!.shutdown();
      this._subsystemManager = undefined;
      this._entityManager = undefined;
      // TODO - Probably other cleanup stuff needed here, will be relevant when we add inter-region teleports.
      this.emitChange();
    });
    //
    try {
      await client.start();
    } catch (failure) {
      console.log("got authFailure");
      this._isLoading = false;
      if (typeof failure === "string") {
        this._errorText = failure;
      } else {
        this._errorText = "Unknown failure";
        console.error("Messenger Start Failure:", failure);
      }
      this.emitChange();
      return;
    }
    this._client = client;
    this._state = State.CONNECTED_GAIA;
    this._isLoading = false;
    this._errorText = undefined;
    this.initializeAuthenticatedRegion();
    this.emitChange();
  };

  private initializeAuthenticatedRegion = () => {
    const subsystemContainer = document.getElementById("subsystem-ui-container");
    if (subsystemContainer === null) {
      throw Error("Couldn't get a reference to the container for subsystem InjectionRenderer");
    }
    this._animationManager = new ClientAnimationManager();
    this._resourceManager = new ClientResourceManager();
    this._entityManager = new EntityManager(this._client!, this._animationManager); // FIXME Lazy
    registerEntities(this._entityManager);
    this._subsystemManager = new SubsystemManager(this.region(), this._client!, subsystemContainer); // FIXME Lazy
    registerSubsystems(this._subsystemManager);
    // TODO - right now, the SubsystemManager needs to be created AFTER the EntityManager. This is ugly, fix eventually...
  };

  // ===========================================================================

  messenger = (): MessengerClient => {
    if (this._state === State.UNAUTHENTICATED) throw new Error("Unable to access messenger, auth incomplete.");
    return this._client!; // FIXME Lazy
  };

  artemisToken = (): string => {
    return this._artemisToken!; // FIXME lazy
  };

  animationManager = (): ClientAnimationManager => {
    return this._animationManager!; // FIXME lazy
  };

  resourceManager = (): ClientResourceManager => {
    return this._resourceManager!; // FIXME lazy
  };

  entityManager = (): EntityManager => {
    return this._entityManager!; // FIXME lazy
  };

  subsystemManager = (): SubsystemManager => {
    return this._subsystemManager!; // FIXME lazy
  };

  region = (): Region => {
    return this._region!; // FIXME lazy
  };

  username = (): string => {
    return this._username!; // FIXME lazy
  };

  userEntityId = (): string => {
    return this._userEntityId!;
  };

  state = () => {
    return this._state;
  };

  isLoading = () => {
    return this._isLoading;
  };

  errorText = () => {
    return this._errorText;
  };

  resource = () => {
    return this._resource!;
  };

  // ===========================================================================

  onChange = (handler: () => void) => {
    this._handlers.push(handler);
  };

  offChange = (handler: () => void) => {
    pull(this._handlers, handler);
  };

  private emitChange = () => {
    this._handlers.forEach((handler) => handler());
  };
}

//

const manager = new SystemStateManager();

const useForceUpdate = () => {
  const [flip, setFlip] = useState(false);
  return () => setFlip(!flip);
};

export const SystemStateRenderer = () => {
  const forceUpdate = useForceUpdate();

  useEffect(() => {
    manager.onChange(forceUpdate);
    return () => manager.offChange(forceUpdate);
  }, [forceUpdate]);

  switch (manager.state()) {
    case State.UNAUTHENTICATED:
      return <Login errorText={manager.errorText()} isLoading={manager.isLoading()} onSubmit={manager.authenticate} />;
    case State.CONNECTED_ARTEMIS:
      return <FriendsList />;
    case State.CONNECTED_GAIA:
      return <Reality />;
  }
};

export default manager;
