import { VoiceConfig } from "@reality/subsystem-voice/lib/common";

export const voiceConfig: VoiceConfig = [
  "audio-tutorial",
  "cool hackers club",
  "U. Village",
  "Suzz.",
  "The Ave.",
  "Snack Overflow",
  "Memorial Way",
  "Denny Field",
  "Maze-start",
  "Maze",
  "Maze-End (Voice)",
  "Status 200",
  "Status 301",
  "Lanterns",
];
