import React, { ReactNode } from "react";
import ReactDOM from "react-dom";

interface InjectionRendererContainerProps {
  contents: Map<string, ReactNode>;
}

class InjectionRendererContainer extends React.Component<InjectionRendererContainerProps> {
  render = () => {
    const contents: React.ReactNode[] = [];
    this.props.contents.forEach((node, key) => {
      contents.push(<React.Fragment key={key}>{node}</React.Fragment>);
    });
    return <>{contents}</>;
  };
}

export class InjectionRenderer {
  private readonly _container: HTMLElement;
  private readonly _contents: Map<string, ReactNode>;
  private readonly _ref: React.RefObject<InjectionRendererContainer>;
  private _released: boolean;

  constructor(container: HTMLElement) {
    this._container = container;
    this._contents = new Map();
    this._ref = React.createRef<InjectionRendererContainer>();
    this._released = false;
    //
    ReactDOM.render(<InjectionRendererContainer ref={this._ref} contents={this._contents} />, container);
  }

  render = (key: string, node: ReactNode) => {
    if (this._released) {
      console.error("InjectionRenderer being used after release. This renderer may no longer be used.");
      return;
    }
    this._contents.set(key, node);
    this.update();
  };

  destroy = (key: string) => {
    if (this._released) {
      console.error("InjectionRenderer being used after release. This renderer may no longer be used.");
      return;
    }
    this._contents.delete(key);
    this.update();
  };

  releaseContainer = () => {
    ReactDOM.unmountComponentAtNode(this._container);
    this._released = true;
  };

  private update = () => {
    const container = this._ref.current;
    if (container === null) {
      console.error("InjectionRenderer container doesn't have a valid instance.");
      return;
    }
    container.forceUpdate();
  };
}
