import React, { useState } from "react";
import { Button, Form, Spinner } from "react-bootstrap";
import styled from "styled-components";
import FullPageCard from "../components/FullPageCard";

const Logo = styled.img`
  width: 200px;
  margin-top: -10px;
`;

const Input = styled(Form.Control)`
  width: 250px;
  margin: 5px 0 5px;
`;

const ErrorText = styled.div`
  color: #ff0000;
  text-align: center;
  max-width: 230px;
  margin: 5px 5px 0 5px;
`;

const LoginForm = styled(Form)`
  display: flex;
  flex-flow: column nowrap;
  justify-content: center;
  align-items: center;
`;

const SubmitButton = styled(Button)`
  margin-top: 20px;
`;

interface LoginProps {
  isLoading: boolean;
  errorText?: string;
  onSubmit: (username: string, password: string) => void;
}

// ===========================================================================

const Login = (props: LoginProps) => {
  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");

  const runSignIn = (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    props.onSubmit(username, password);
  };

  const canSubmit = username.length > 0 && password.length > 0 && !props.isLoading;

  return (
    <FullPageCard>
      <Logo src="/logo.png" alt="DubHacks" />
      <LoginForm onSubmit={runSignIn}>
        <Input autoFocus placeholder="Username" value={username} onChange={(e: any) => setUsername(e.target.value)} />
        <Input placeholder="Password" value={password} onChange={(e: any) => setPassword(e.target.value)} type="password" />
        {props.errorText ? <ErrorText>{props.errorText}</ErrorText> : null}
        <SubmitButton variant="primary" type="submit" disabled={!canSubmit}>
          {props.isLoading ? <Spinner animation="border" as="span" size="sm" /> : "Sign In"}
        </SubmitButton>
      </LoginForm>
    </FullPageCard>
  );
};

export default Login;
