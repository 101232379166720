// react + redux
import { CSEntity } from "@reality/entity/lib/entity/CSEntity";
import { CSSharedEntity } from "@reality/entity/lib/entity/CSSharedEntity";
import { CoordinateUtils } from "@reality/euclid";

// custom components
import RealityRenderer from "@reality/renderer";
import dh from "@reality/spritesheets/lib/dh";

import player from "@reality/spritesheets/lib/player";
import region1 from "@reality/spritesheets/lib/region1";
import lantern from "@reality/spritesheets/lib/lantern";
import React, { useEffect, useState } from "react";
import { TILE_SIZE, VIEWPORT_TILES_HEIGHT, VIEWPORT_TILES_WIDTH } from "../../constants";
import manager from "../state/SystemStateManager";

// constants
import "./Reality.css";

const Reality = () => {
  const [loaded, setLoaded] = useState(false);

  useEffect(() => {
    Promise.all([
      manager.resourceManager().storeResource("player", player),
      manager.resourceManager().storeResource("sprites1", region1),
      manager.resourceManager().storeResource("lantern", lantern),
      manager.resourceManager().storeResource("dh", dh),
    ]).then(() => {
      setLoaded(true);
    });
  }, []);

  // handle listening for navigation
  // TODO - This should eventually be moved to the entity manager, and will need some focus management so it doesn't intercept everything.
  useEffect(() => {
    const handleKeyDown = (keydownEvent: any) => manager.entityManager().emit("keydown", keydownEvent);
    const handleKeyUp = (keyupEvent: any) => manager.entityManager().emit("keyup", keyupEvent);

    document.addEventListener("keydown", handleKeyDown);
    document.addEventListener("keyup", handleKeyUp);
    // Remove event listener on cleanup
    return () => {
      document.removeEventListener("keydown", handleKeyDown);
      document.removeEventListener("keyup", handleKeyUp);
    };
  });

  if (!loaded) {
    return <div>..loading..</div>;
  }

  const getCenterCoord = () => {
    let playerEntity: CSEntity<unknown> | null = null;
    for (const entity of manager.entityManager().getEntityList()) {
      if (
        entity.baseManifest.name === "Player" &&
        (entity as CSSharedEntity<{ forPlayer: string }, {}>).sharedStore.forPlayer === manager.username()
      ) {
        playerEntity = entity;
      }
    }

    if (playerEntity) {
      const ds = manager.animationManager().getAnimationDisplacement(playerEntity.instanceID);
      return CoordinateUtils.add(CoordinateUtils.add(playerEntity.entityState.position, ds), { x: 0.5, y: 0.5 });
    } else {
      return { x: 1.5, y: 1.5 };
    }
  };

  return (
    <div className="reality-wrapper" id="reality-wrapper">
      <RealityRenderer
        resourceManager={manager.resourceManager()}
        animationManager={manager.animationManager()}
        entityManager={manager.entityManager()}
        canvasStyles={{
          height: "min(100vw, 100vh)",
          minWidth: "min(100vw, 100vh)",
        }}
        region={manager.region()}
        getCenterCoord={getCenterCoord}
        renderOptions={{
          canvasHeight: VIEWPORT_TILES_HEIGHT * TILE_SIZE * 2,
          canvasWidth: VIEWPORT_TILES_WIDTH * TILE_SIZE * 2,
          viewportHeight: VIEWPORT_TILES_HEIGHT,
          viewportWidth: VIEWPORT_TILES_WIDTH,
          tileSize: TILE_SIZE,
        }}
        showStats={process.env.NODE_ENV === "development"}
      />
    </div>
  );
};

export default Reality;
