import ClientAnimationManager from "@reality/entity/lib/client-utils/ClientAnimationManager";
import ClientEntityContext from "@reality/entity/lib/client-utils/ClientEntityContext";
import NewEntityManager from "@reality/entity/lib/client-utils/NewEntityManager";
import { CSClientOnlyEntity } from "@reality/entity/lib/clientOnly/CSClientOnlyEntity";
import { CSEntity, CSEntityEvents, EntityMoveEvent } from "@reality/entity/lib/entity/CSEntity";
import { CSRegionEntity } from "@reality/entity/lib/region/CSRegionEntity";
import { EntityManagementMessages, EntityState } from "@reality/entity/lib/types";
import { ClientOnlyEntityManifest, EntityType, SharedEntityManifest } from "@reality/entity/lib/types/EntityManifest";
import ChannelNames from "@reality/gaia-channels/lib/ChannelNames";
import { MessengerClient } from "@reality/messenger/lib/client";
import { ClientGroupFor } from "@reality/messenger/lib/client/util";
import { PayloadOf } from "@reality/messenger/lib/common/util";
import { EventEmitter } from "ee-ts";
import { EventIn, EventKey } from "ee-ts/lib/types";
import { pull } from "lodash";
import { v4 as uuid } from "uuid";
import manager from "../state/SystemStateManager";

type EventDispatcher<Events> = Pick<EventEmitter<Events>, "emit">;
type MoveHandler = (event: EntityMoveEvent, entity: CSEntity<unknown>) => void;

interface RegionEntityImplementation {
  manifest: SharedEntityManifest<any, any>;
  client: (instance: CSRegionEntity<any, any>, gameClient: ClientEntityContext) => void;
}

interface ClientOnlyEntityImplementation {
  manifest: ClientOnlyEntityManifest<unknown>;
  client: (instance: CSClientOnlyEntity<unknown>, gameClient: ClientEntityContext) => void;
}

/**
 * Maintains the list of all entities within the region.
 * Responsible for:
 *  Creating new entities.
 *  Emitting events to all entities.
 *  Broadcasting all entity events to concerned systems.
 */
// TODO - This code assumes only RegionEntities in parts - needs to be expanded to support everything.
export default class EntityManager implements NewEntityManager, EventDispatcher<CSEntityEvents> {
  private readonly _knownRegionEntityImplementations: { [entityID: string]: RegionEntityImplementation } = {};
  private readonly _regionEntities: { [instanceID: string]: CSEntity<unknown> } = {};
  private readonly _knownClientOnlyEntityImplementations: { [entityID: string]: ClientOnlyEntityImplementation } = {};
  private readonly _clientOnlyEntities: { [instanceID: string]: CSClientOnlyEntity<unknown> } = {};
  //
  private readonly _messages: ClientGroupFor<typeof EntityManagementMessages>;
  private readonly _animationManager: ClientAnimationManager;
  private readonly _moveHandlers: MoveHandler[];

  constructor(messenger: MessengerClient, animationManager: ClientAnimationManager) {
    this._animationManager = animationManager;
    this._moveHandlers = [];
    //
    this._messages = messenger.openChannel("GaiaSystem", ChannelNames.ENTITY_MANAGEMENT).registerGroup(EntityManagementMessages);
    this._messages.CreateEntity.handleAll(this.createEntityFromServer);
    this._messages.DestroyEntity.handleAll(this.destroyEntityFromServer);
  }

  fakeDynamicLoadRegionEntityImplementation = (manifest: SharedEntityManifest<any, any>, client: RegionEntityImplementation["client"]) => {
    const existing = this._knownRegionEntityImplementations[manifest.id];
    if (existing !== undefined) {
      throw Error(`Loading region entity '${manifest.id}' that's already loaded`);
    }
    this._knownRegionEntityImplementations[manifest.id] = { manifest, client };
    console.log(`Region entity '${manifest.id}' loaded`);
  };

  fakeDynamicLoadClientOnlyEntityImplementation = (
    manifest: ClientOnlyEntityManifest<any>,
    client: ClientOnlyEntityImplementation["client"]
  ) => {
    const existing = this._knownClientOnlyEntityImplementations[manifest.id];
    if (existing !== undefined) {
      throw Error(`Loading client-only entity '${manifest.id}' that's already loaded`);
    }
    this._knownClientOnlyEntityImplementations[manifest.id] = { manifest, client };
    console.log(`Client-only entity '${manifest.id}' loaded`);
  };

  private createEntityFromServer = (payload: PayloadOf<typeof EntityManagementMessages.CreateEntity>) => {
    const existing = this._knownRegionEntityImplementations[payload.entityId];
    if (existing === undefined) {
      throw Error(`Server issue a create for unknown entity: '${payload.entityId}'`);
    }
    if (existing.manifest.type !== EntityType.REGION_ENTITY) {
      throw Error(`Only REGION_ENTITY is currently supported, requested: ${payload}`);
    }
    // TODO - This is not type-safe on the sharedStore. There are no serialization checks for the shape of that field.
    const e = new CSRegionEntity(
      existing.manifest,
      payload.instanceId,
      {},
      payload.entityState,
      payload.sharedStore,
      this._messages,
      this._animationManager
    );
    try {
      existing.client(e, new ClientEntityContext(manager.animationManager(), manager.region(), manager.username()));
    } catch (e) {
      console.error(`Error executing client implementation for region entity '${payload.entityId}'`, e);
      console.error("Entity will be destroyed");
      throw Error("Unable to create region entity. Implementation error: " + e);
    }
    this._regionEntities[e.instanceID] = e;
    if (e.manifest.id === "player" && (e.sharedStore as { forPlayer: string }).forPlayer === manager.username()) {
      manager.initializeUserEntityId(e.instanceID);
    }
    e.on("move", (event) => this.dispatchEntityMove(event, e));
    console.log(`Server created entity. ID: '${payload.entityId}' - Instance: @${payload.instanceId}`);
  };

  private destroyEntityFromServer = (payload: PayloadOf<typeof EntityManagementMessages.DestroyEntity>) => {
    console.log("Entity destroyed by server:", payload.instanceId);
    this.removeEntity(payload.instanceId);
  };

  getEntityList(): IterableIterator<CSEntity<unknown>> {
    return [...Object.values(this._regionEntities), ...Object.values(this._clientOnlyEntities)][Symbol.iterator]();
  }

  createClientOnlyEntity = (id: string, initialState: EntityState): CSClientOnlyEntity<unknown> => {
    const existing = this._knownClientOnlyEntityImplementations[id];
    if (existing === undefined) {
      throw Error(`Got a create for unknown client-only entity: '${id}'`);
    }
    if (existing.manifest.type !== EntityType.CLIENT_ONLY_ENTITY) {
      throw Error(`Only CLIENT_ONLY_ENTITY is currently supported, requested: ${existing.manifest.type}`);
    }
    // TODO - This is not type-safe on the sharedStore. There are no serialization checks for the shape of that field.
    const e = new CSClientOnlyEntity(existing.manifest, uuid(), initialState, {}, this._animationManager);
    try {
      existing.client(e, new ClientEntityContext(manager.animationManager(), manager.region(), manager.username()));
    } catch (e) {
      console.error(`Error executing client implementation for client-only entity '${id}'`, e);
      console.error("Entity will be destroyed");
      throw Error("Unable to create client-only entity. Implementation error: " + e);
    }
    this._clientOnlyEntities[e.instanceID] = e;
    e.on("move", (event) => this.dispatchEntityMove(event, e));
    console.log(`Client-only entity created. ID: '${id}' - Instance: @${e.instanceID}`);
    return e;
  };

  removeEntity = (instanceID: string) => {
    let entity: CSEntity<unknown> | undefined;
    entity = this._regionEntities[instanceID];
    if (entity === undefined) {
      entity = this._clientOnlyEntities[instanceID];
    }
    if (entity === undefined) {
      console.warn(`Warning: Request to destroy an entity that didn't exist for us: @${instanceID}`);
      return;
    }
    entity.emit("destroy");
    entity.off("*"); // Remove all listeners for all events
    delete this._regionEntities[instanceID];
    delete this._clientOnlyEntities[instanceID];
  };

  // ------------------------------------------------------------------------------------

  private dispatchEntityMove = (event: EntityMoveEvent, entity: CSEntity<unknown>) => {
    this._moveHandlers.forEach((handler) => handler(event, entity));
  };

  // TODO - Un-specialize this so we aren't so coupled to the Subsystem implementation
  onEntityMove = (handler: MoveHandler) => {
    this._moveHandlers.push(handler);
  };

  offEntityMove = (handler: MoveHandler) => {
    pull(this._moveHandlers, handler);
  };

  // ------------------------------------------------------------------------------------

  /**
   * Emits an event to all entities managed by this.
   *
   * @param key The event name or key.
   * @param args The arguments to the event handler for this event.
   */
  emit = <K extends EventKey<CSEntityEvents>>(key: K, ...args: EventIn<CSEntityEvents, K>) => {
    Object.values(this._regionEntities).forEach((entity) => entity.emit(key, ...args));
  };
}
