import { CSEntity, EntityMoveEvent } from "@reality/entity/lib/entity/CSEntity";
import { EntityState } from "@reality/entity/lib/types";
import { MessengerClient } from "@reality/messenger/lib/client";
import { Region } from "@reality/region-utils";
import { ClientSubsystem, ClientSubsystemEntityManagement, ReactSubsystemContext } from "@reality/subsystem/lib/client/ClientSubsystem";
import { ClientSubsystemContext } from "@reality/subsystem/lib/client/ClientSubsystemContext";
import { SubsystemManifest } from "@reality/subsystem/lib/common/SubsystemManifest";
import { difference } from "lodash";
import React from "react";
import manager from "../state/SystemStateManager";
import { InjectionRenderer } from "../ui/InjectionRenderer";

export type ClientImplementation = (self: ClientSubsystem, context: ClientSubsystemContext) => void;

export default class SubsystemManager {
  private readonly _region: Region;
  private readonly _messenger: MessengerClient;
  private readonly _subsystems: { [id: string]: ClientSubsystem };
  private readonly _uiRenderer: InjectionRenderer;

  constructor(region: Region, messenger: MessengerClient, uiContainer: HTMLElement) {
    this._region = region;
    this._messenger = messenger;
    this._subsystems = {};
    this._uiRenderer = new InjectionRenderer(uiContainer);
    manager.entityManager().onEntityMove(this.handleEntityMove);
  }

  fakeDynamicLoadSubsystem = (manifest: SubsystemManifest, client: ClientImplementation, regionConfig: unknown) => {
    // TODO - Eventually regionConfig will come from the region definition file. Right now, we're fake loading
    //        it alongside the implementation, so we don't have to add editor support for subsystem configurations.
    const existing = this._subsystems[manifest.id];
    if (existing !== undefined) {
      throw Error(`Registering subsystem '${manifest.id}' that has already been registered`);
    }
    //
    const reactContext: ReactSubsystemContext = {
      destroyNode: () => this._uiRenderer.destroy(manifest.id),
      injectNode: (node: React.ReactNode) => this._uiRenderer.render(manifest.id, node),
    };
    const channel = this._messenger.openChannel("subsystem", manifest.id);
    const entityManagement: ClientSubsystemEntityManagement = {
      createClientOnlyEntity: (id: string, initialState: EntityState) => manager.entityManager().createClientOnlyEntity(id, initialState),
      getEntityList: () => manager.entityManager().getEntityList(),
      removeEntity: (instanceID: string) => manager.entityManager().removeEntity(instanceID),
    };
    const subsystem = new ClientSubsystem(manifest, channel, reactContext, regionConfig, entityManagement);
    const clientContext: ClientSubsystemContext = {
      userEntityId: () => manager.userEntityId(),
      region: () => manager.region(),
    };
    //
    try {
      client(subsystem, clientContext);
    } catch (e) {
      console.error(`Error executing subsystem implementation for subsystem '${manifest.id}':`, e);
      console.error("Subsystem will be unloaded...");
      return;
    }
    //
    this._subsystems[manifest.id] = subsystem;
    console.log(`Subsystem '${manifest.id}' loaded`);
  };

  shutdown = () => {
    this.forEachSubsystem((subsystem) => subsystem.emit("shutdown"));
    this._uiRenderer.releaseContainer();
    manager.entityManager().offEntityMove(this.handleEntityMove);
  };

  private forEachSubsystem = (handler: (subsystem: ClientSubsystem) => void) => {
    Object.values(this._subsystems).forEach(handler);
  };

  private handleEntityMove = (event: EntityMoveEvent, entity: CSEntity<unknown>) => {
    const oldPosZones = this._region.map[event.oldPos.x][event.oldPos.y].zones ?? [];
    const newPosZones = this._region.map[event.newPos.x][event.newPos.y].zones ?? [];
    const enteringZones = difference(newPosZones, oldPosZones);
    const exitingZones = difference(oldPosZones, newPosZones);
    //
    this.forEachSubsystem((subsystem) => {
      subsystem.emit("entityMove", event, entity);
      exitingZones.forEach((zone) => {
        subsystem.emit("zoneExit", zone, entity);
      });
      enteringZones.forEach((zone) => {
        subsystem.emit("zoneEntry", zone, entity);
      });
    });
  };
}
