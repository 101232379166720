import * as React from "react";
import { BrowserRouter, Route, Switch } from "react-router-dom";
import AccountCreate from "./features/pages/AccountCreate";
import PasswordReset from "./features/pages/PasswordReset";
import { SystemStateRenderer } from "./features/state/SystemStateManager";

function App() {
  return (
    <div className="App">
      {/* TODO - this should probably be somewhere else - needs to be here for now for bootstrapping reasons */}
      <div id="subsystem-ui-container" />
      <BrowserRouter>
        <Switch>
          <Route path="/createAccount" component={AccountCreate} />
          <Route path="/resetPassword" component={PasswordReset} />
          <Route path="/" component={SystemStateRenderer} />
        </Switch>
      </BrowserRouter>
    </div>
  );
}

export default App;
