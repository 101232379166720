import { SignConfig } from "@reality/subsystem-signs/lib/common";

export const signConfig: SignConfig = [
  {
    title: "Welcome to Reality!",
    text:
      "Enter dirt patches to chat with other people, walk to the one north of here to setup your microphone! Provide feedback and ask questions in Slack @ #reality. When you're ready click the button below! (You can walk around this sign)",
    pos: {
      x: 7,
      y: 10,
    },
    actions: [
      {
        label: "Enter Reality",
        value: "enter",
      },
    ],
  },
  {
    title: "Be on the look out in Slack for the secret code..",
    text: "",
    pos: {
      x: 33,
      y: 18,
    },
    actions: [],
  },
  {
    title: "Slack: #reality",
    text: "Tell us your thoughts :D",
    pos: {
      x: 33,
      y: 42,
    },
    actions: [],
  },
  {
    title: "Want to go to the maze?",
    text: "I can take you there",
    pos: {
      x: 41,
      y: 27,
    },
    actions: [
      {
        label: "Go to the maze",
        value: "enter-maze",
      },
    ],
  },
  {
    title: "Welcome to the maze",
    text: "Just walk right in! (it's a voice room)",
    pos: {
      x: 67,
      y: 34,
    },
    actions: [
      {
        label: "Return to the mainland",
        value: "leave-maze",
      },
    ],
  },
  {
    title: "Congratulations!",
    text: "You found your way through the maze, this sign can teleport you back to the start when you're ready to leave.",
    pos: {
      x: 55,
      y: 12,
    },
    actions: [
      {
        label: "Back to the entrance!",
        value: "enter-maze",
      },
    ],
  },
  {
    title: "A Mystery Lies Ahead",
    text:
      "Before you lies a mysterious arrangement of lanterns and pedestals. It seems like there's a code you must decipher. You've heard rumors that at least 5 people must work together to discover the mysteries of the lantern. (Entering this area takes you into a voice room - work together to solve the puzzle!)",
    pos: {
      x: 47,
      y: 42,
    },
    actions: [],
  },
  {
    title: "The Puzzle of the Lanterns",
    text:
      "The best thing to do, when faced with a challenge, is to grab a byte to eat and spell out your situation. This event is the key, and the lanterns will guide you along the way. To succeed, you must banish the darkness completely. The legend says the steps of one hundred will start you on your way.",
    pos: {
      x: 58,
      y: 44,
    },
    actions: [],
  },
  {
    title: "Well played",
    text: "ISupposeInThisCaseSideEyeWasAGoodThing",
    pos: {
      x: 74,
      y: 9,
    },
    actions: [],
  },
  {
    title: "Well played",
    text: "IfOnlyStrangeGreenMushroomsInMazesLetYouLiveAgain",
    pos: {
      x: 63,
      y: 19,
    },
    actions: [],
  },
];
