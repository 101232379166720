import React, { useEffect, useState } from "react";
import { Button, Form } from "react-bootstrap";
import { useHistory, useLocation } from "react-router";
import styled from "styled-components";
import manager from "../state/SystemStateManager";
import FullPageCard from "../components/FullPageCard";

const Logo = styled.img`
  width: 200px;
  margin-top: -10px;
`;

const Input = styled(Form.Control)`
  width: 250px;
  margin: 5px 0 5px;
`;

const ErrorText = styled.div`
  color: #ff0000;
  text-align: center;
  max-width: 230px;
  margin: 5px 5px 0 5px;
`;

const InlineSuccess = styled.span`
  color: #7bbc5d;
`;

const InlineError = styled.span`
  color: #f37776;
`;

const HelperText = styled.div`
  color: #5d9bbc;
  font-size: 0.8rem;
  margin: 0 0 15px 10px;
  align-self: flex-start;
`;

const DashSpacer = styled(({ className }: { className?: string }) => <span className={className}>-</span>)`
  margin: 0 3px;
`;

const CreateForm = styled(Form)`
  display: flex;
  flex-flow: column nowrap;
  justify-content: center;
  align-items: center;
`;

const SubmitButton = styled(Button)`
  margin-top: 20px;
`;

function useQuery() {
  return new URLSearchParams(useLocation().search);
}

const AccountCreate = () => {
  const [errorText, setErrorText] = useState<string | undefined>(undefined);
  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");
  const [hasSubmitted, setHasSubmitted] = useState(false);
  const [usernameAvailable, setUsernameAvailable] = useState<boolean | undefined>(true);
  const createToken = useQuery().get("token");
  const history = useHistory();

  let tokenOk = true;
  if (createToken === null || createToken.length === 0) {
    const text = "Invalid account creation token. Check the link you used to get to this page.";
    if (errorText !== text) setErrorText(text);
    tokenOk = false;
  }

  const submit = async (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    setErrorText(undefined);
    setHasSubmitted(true);
    const res = await manager.artemisAPI().account.create.send({
      username,
      password,
      createToken: createToken!,
    });
    //
    setHasSubmitted(false);
    if (!res.success) {
      if (res.messageForUser) {
        setErrorText(res.message);
      } else {
        console.error("Create Account Error:", res.message);
        setErrorText("An unknown error prevented account creation. Please try again.");
      }
      return;
    }
    //
    manager.completeArtemisConnection(res.body.artemisToken, username);
    history.push("/"); // Redirect to main page
  };

  useEffect(() => {
    setUsernameAvailable(undefined);
    if (username.length === 0) return;
    //
    const doCheck = async () => {
      const isAvailable = await manager.artemisAPI().account.usernameExists.send({ username });
      if (!isAvailable.success) {
        setUsernameAvailable(undefined);
        setErrorText("Unable to determine if the username is available. Please reload the page.");
      } else {
        setUsernameAvailable(!isAvailable.body.exists);
        setErrorText(undefined);
      }
    };
    //
    const timeoutId = setTimeout(doCheck, 300);
    return () => clearTimeout(timeoutId);
  }, [username]);

  let usernameMessage;
  let usernameOk = false;
  if (username.length === 0) {
    usernameMessage = <span>Keep it PG</span>;
  } else if (username.length > 20) {
    usernameMessage = <InlineError>Cannot be more than 20 characters</InlineError>;
  } else if (!/^[A-Za-z0-9]+$/.test(username)) {
    usernameMessage = <InlineError>Username must be alphanumeric</InlineError>;
  } else if (usernameAvailable === undefined) {
    usernameMessage = <span>Keep it PG</span>;
  } else {
    if (usernameAvailable) {
      usernameMessage = (
        <span>
          Keep it PG
          <DashSpacer />
          <InlineSuccess>Available</InlineSuccess>
        </span>
      );
    } else {
      usernameMessage = (
        <span>
          Keep it PG
          <DashSpacer />
          <InlineError>Unavailable</InlineError>
        </span>
      );
    }
    usernameOk = usernameAvailable;
  }

  let passwordMessage;
  let passwordOk = false;
  if (password.length === 0) {
    passwordMessage = <span>At least 12 characters</span>;
  } else if (password.length < 12) {
    passwordMessage = <InlineError>At least 12 characters</InlineError>;
  } else if (/* eslint-disable-line */ !/^[A-Za-z0-9~`! @#$%^&*()_\-+={\[}\]|\\:;"'<,>.?/]*$/.test(password)) {
    passwordMessage = <InlineError>Password contains an invalid character</InlineError>;
  } else {
    passwordMessage = <InlineSuccess>Looks good!</InlineSuccess>;
    passwordOk = true;
  }

  return (
    <FullPageCard>
      <Logo src="/logo.png" alt="DubHacks" />
      <CreateForm onSubmit={submit}>
        <Input placeholder="Username" value={username} onChange={(e: React.ChangeEvent<HTMLInputElement>) => setUsername(e.target.value)} />
        <HelperText>{usernameMessage}</HelperText>
        <Input
          placeholder="Password"
          value={password}
          type="password"
          onChange={(e: React.ChangeEvent<HTMLInputElement>) => setPassword(e.target.value)}
        />
        <HelperText>{passwordMessage}</HelperText>
        {errorText !== undefined ? <ErrorText>{errorText}</ErrorText> : null}
        <SubmitButton type="submit" disabled={!(usernameOk && passwordOk && tokenOk) || hasSubmitted}>
          Create Account
        </SubmitButton>
      </CreateForm>
    </FullPageCard>
  );
};

export default AccountCreate;
