import React, { useState } from "react";
import { Button, Form } from "react-bootstrap";
import { useHistory, useLocation } from "react-router";
import styled from "styled-components";
import manager from "../state/SystemStateManager";
import FullPageCard from "../components/FullPageCard";

const Logo = styled.img`
  width: 200px;
  margin-top: -10px;
`;

const Input = styled(Form.Control)`
  width: 250px;
  margin: 5px 0 5px;
`;

const ErrorText = styled.div`
  color: #ff0000;
  text-align: center;
  max-width: 230px;
  margin: 5px 5px 0 5px;
`;

const InlineSuccess = styled.span`
  color: #7bbc5d;
`;

const InlineError = styled.span`
  color: #f37776;
`;

const HelperText = styled.div`
  color: #5d9bbc;
  font-size: 0.8rem;
  margin: 0 0 15px 10px;
  align-self: flex-start;
`;

const CreateForm = styled(Form)`
  display: flex;
  flex-flow: column nowrap;
  justify-content: center;
  align-items: center;
`;

const SubmitButton = styled(Button)`
  margin-top: 20px;
`;

function useQuery() {
  return new URLSearchParams(useLocation().search);
}

const PasswordReset = () => {
  const [errorText, setErrorText] = useState<string | undefined>(undefined);
  const [password, setPassword] = useState("");
  const [hasSubmitted, setHasSubmitted] = useState(false);
  const resetToken = useQuery().get("token");
  const history = useHistory();

  let tokenOk = true;
  if (resetToken === null || resetToken.length === 0) {
    const text = "Invalid password reset token. Check the link you used to get to this page.";
    if (errorText !== text) setErrorText(text);
    tokenOk = false;
  }

  const submit = async (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    setErrorText(undefined);
    setHasSubmitted(true);
    const res = await manager.artemisAPI().account.resetPassword.send({
      resetToken: resetToken!,
      password: password,
    });
    //
    setHasSubmitted(false);
    if (!res.success) {
      if (res.messageForUser) {
        setErrorText(res.message);
      } else {
        console.error("Password Reset Error:", res.message);
        setErrorText("An unknown error prevented reset. Please try again.");
      }
      return;
    }
    // Deliberately not awaiting:
    manager.completeArtemisConnection(res.body.artemisToken, res.body.username);
    history.push("/"); // Redirect to main page
  };

  let passwordMessage;
  let passwordOk = false;
  if (password.length === 0) {
    passwordMessage = <span>At least 12 characters</span>;
  } else if (password.length < 12) {
    passwordMessage = <InlineError>At least 12 characters</InlineError>;
  } else if (/* eslint-disable-line */ !/^[A-Za-z0-9~`! @#$%^&*()_\-+={\[}\]|\\:;"'<,>.?/]*$/.test(password)) {
    passwordMessage = <InlineError>Password contains an invalid character</InlineError>;
  } else {
    passwordMessage = <InlineSuccess>Looks good!</InlineSuccess>;
    passwordOk = true;
  }

  return (
    <FullPageCard>
      <Logo src="/logo.png" alt="DubHacks" />
      <CreateForm onSubmit={submit}>
        <Input
          placeholder="Password"
          value={password}
          type="password"
          onChange={(e: React.ChangeEvent<HTMLInputElement>) => setPassword(e.target.value)}
        />
        <HelperText>{passwordMessage}</HelperText>
        {errorText !== undefined ? <ErrorText>{errorText}</ErrorText> : null}
        <SubmitButton type="submit" disabled={!passwordOk || hasSubmitted || !tokenOk}>
          Reset Password
        </SubmitButton>
      </CreateForm>
    </FullPageCard>
  );
};

export default PasswordReset;
