import LanternClient from "@reality/entity-lantern/lib/client";
import LanternManifest from "@reality/entity-lantern/lib/manifest";
import PedestalClient from "@reality/entity-pedestal/lib/client";
import PedestalManifest from "@reality/entity-pedestal/lib/manifest";
import PlayerClient from "@reality/entity-player/lib/client";
import PlayerManifest from "@reality/entity-player/lib/manifest";
import EntityManager from "../EntityManager";

export const registerEntities = (manager: EntityManager) => {
  // TODO - Remember to add entity dependencies to package.json
  manager.fakeDynamicLoadRegionEntityImplementation(PlayerManifest, PlayerClient);
  manager.fakeDynamicLoadRegionEntityImplementation(LanternManifest, LanternClient);
  manager.fakeDynamicLoadRegionEntityImplementation(PedestalManifest, PedestalClient);
};
