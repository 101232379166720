import { without } from "lodash";
import React, { useEffect, useState } from "react";
import styled from "styled-components";
import SystemState from "../state/SystemStateManager";
import FullPageCard from "./FullPageCard";
import { Button, Spinner } from "react-bootstrap";

const Logo = styled.img`
  width: 200px;
  margin-top: -10px;
`;

const Container = styled.div`
  width: 100%;
  display: flex;
  flex-flow: column nowrap;
`;

const FriendsArea = styled.div`
  width: 100%;
  display: flex;
  flex-flow: column nowrap;
  max-height: 225px;
  overflow-y: scroll;
`;

const FriendsTitle = styled.div`
  font-size: 18px;
  text-align: center;
`;

const HelperText = styled.div`
  font-size: 12px;
  text-align: center;
  max-width: 250px;
  margin: 2px auto 15px;
  color: #ccc;
`;

const FriendEntryContainer = styled.div`
  display: flex;
  flex-flow: row nowrap;
  justify-content: space-between;
  background: rgb(26, 31, 53);
  width: 100%;
  margin: 2px 0;
  padding: 5px;
  border: 1px solid rgb(89, 93, 112);
  font-size: 14px;
`;

const RemoveFriendButton = styled.button`
  background: rgb(26, 31, 53);
  border: 1px solid rgb(89, 93, 112);
  color: white;
  font-weight: bold;

  :hover {
    background: rgb(44, 49, 84);
  }
`;

const AddFriendForm = styled.form`
  width: 100%;
  display: flex;
  flex-flow: row nowrap;
  margin-top: 5px;
`;

const AddFriendField = styled.input`
  flex-grow: 1;
`;

const AddFriendButton = styled.button`
  background: rgb(26, 31, 53);
  border: 1px solid rgb(89, 93, 112);
  color: white;
  margin-left: 5px;

  :hover {
    background: rgb(44, 49, 84);
  }
`;

const JoinButton = styled(Button)`
  margin-top: 20px;
`;

interface FriendEntryProps {
  name: string;
  remove: () => void;
}

const FriendEntry = (props: FriendEntryProps) => {
  return (
    <FriendEntryContainer>
      {props.name}
      <RemoveFriendButton onClick={props.remove}>X</RemoveFriendButton>
    </FriendEntryContainer>
  );
};

const MAX_USERNAME_LENGTH = 20;
const MAX_FRIENDS = 7;

export const FriendsList = () => {
  const [friendText, setFriendText] = useState("");
  const [friends, setFriends] = useState<string[]>([]);
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    (async () => {
      const res = await SystemState.artemisAPI().friends.get.send({}, SystemState.artemisToken());
      if (res.success) {
        setFriends(res.body.friends);
      }
    })();
  }, []);

  const addFriend = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    if (friendText.length === 0) return;
    if (friends.includes(friendText)) return;
    if (friends.length > MAX_FRIENDS) return;
    await SystemState.artemisAPI().friends.add.send({ friend: friendText }, SystemState.artemisToken());
    setFriends([...friends, friendText]);
    setFriendText("");
  };
  const removeFriend = (name: string) => async () => {
    if (!friends.includes(name)) return;
    await SystemState.artemisAPI().friends.remove.send({ friend: name }, SystemState.artemisToken());
    setFriends(without(friends, name));
  };
  const editText = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (e.target.value.length > MAX_USERNAME_LENGTH) return;
    setFriendText(e.target.value);
  };
  const joinReality = async () => {
    setIsLoading(true);
    await SystemState.joinRegion();
    setIsLoading(false);
  };
  const disabled = friends.length >= MAX_FRIENDS;

  return (
    <FullPageCard>
      <Logo src="/logo.png" alt="DubHacks" />
      <Container>
        <FriendsTitle>Friends List</FriendsTitle>
        <HelperText>Add people to your friends list to join the same region.</HelperText>
        <FriendsArea>
          {friends.map((name) => (
            <FriendEntry name={name} remove={removeFriend(name)} />
          ))}
        </FriendsArea>
        <AddFriendForm onSubmit={addFriend}>
          <AddFriendField
            placeholder="Username"
            value={disabled ? "(7 friends max)" : friendText}
            onChange={editText}
            disabled={disabled}
          />
          <AddFriendButton type={"submit"}>Add</AddFriendButton>
        </AddFriendForm>
      </Container>
      <JoinButton variant="primary" onClick={joinReality}>
        {isLoading ? <Spinner animation="border" as="span" size="sm" /> : "Enter Reality"}
      </JoinButton>
    </FullPageCard>
  );
};
